


const Header = () => {
	return (
        <div className="StickyHead">
		<nav className="navbar navbar-custom navbar-expand-lg navbar-dark ">
			 <div className="App-header">
			<a>చిట్టి ప్రభు చిలక పలుకులు!</a>
			<a>**** శతకము ****</a>
    </div>
		</nav>
        </div>
	);
};

export default Header;